import authStore from "./AuthStore";
import commonStore from "./CommonStore";
// import userStore from "./UserStore";
// import adminStore from "./AdminStore";
// import orderStore from "./OrderStore";
// import logCallStore from "./LogCallStore";
// import settingStore from "./SettingStore";
// import reportStore from "./ReportStore";
// import HPHongkongStore from "./HPHongkongStore";
// import merchantStore from  "./MerchantStore";
// import lateUserStore from './LateUserStore';

export default {
  authStore,
  commonStore,
  // userStore,
  // adminStore,
  // orderStore,
  // logCallStore,
  // settingStore,
  // reportStore,
  // HPHongkongStore,
  // merchantStore,
  // lateUserStore
};
