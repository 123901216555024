import CommonStore from "./CommonStore";
import { loginWithEmailAndPassword, getAdminPermission, logout } from "../services/AuthService";
import { observable, action, decorate, computed } from "mobx";
import { reactLocalStorage } from "reactjs-localstorage";
import {env, RegionUrl} from '../config/config';
import { clearLS } from "../helper/functions/functions";
import { LSNAME } from "../helper/constants/constants";

class AuthStore {
  currentToken = null;
  currentUser = null;
  authLoading = false;

  constructor() {
    const currentUserString = reactLocalStorage.get(LSNAME.ADMIN);

    if (currentUserString) {
      this.currentUser = JSON.parse(currentUserString);
    }

    const currentTokenString = reactLocalStorage.get(LSNAME.TOKEN);
    if (currentTokenString) {
      this.currentToken = currentTokenString;
    } else {
      clearLS();
      this.currentUser = null;
      this.currentToken = null;
    }


  }

  getAdminPermission = async (url) => {
    let { data, error } = await getAdminPermission(url);
    if (error) return {error};
    if (data) {
      reactLocalStorage.setObject(LSNAME.PERMISSION, data.adminPermission);
      return {data}
    }
  }

  signInWithEmailAndPassword = async ({ username, password, region, history }) => {
   try {
    clearLS();
    // deleteCookiesByName("ideal_current_token");
    const url = RegionUrl[region][env].adminUrl;
    this.currentUser = null;
    this.currentToken = null;
    this.authLoading = true;
    let token = "";
    let { data, error } = await loginWithEmailAndPassword({
      url,
      username,
      password,
      token
    });
    if (error) throw error;

    if (data) {
      this.currentUser = data.admin;
      this.currentToken = data.token;
      reactLocalStorage.setObject(LSNAME.ADMIN, data.admin);
      reactLocalStorage.setObject(LSNAME.DASHBOARD, data.admin.newDashboard);
      reactLocalStorage.setObject(LSNAME.TOKEN, data.token);
      reactLocalStorage.setObject(LSNAME.LIST_REGION, data.admin.region);
      let reg = region;
      if (data.admin.region && data.admin.region.length > 0) {
        if (data.admin.region.includes(region)) {
          reg = region;
        } else {
          reg = data.admin.region[0];
        }
      }
      reactLocalStorage.set(LSNAME.REGION, reg);
  
      // updateConfig();
      const url = RegionUrl[reg][env].adminUrl;

      const permission = await this.getAdminPermission(url);

      if (permission.error) throw permission.error;
      
      this.authLoading = false;
      
      window.location.href = `/${reg}/dashboard`;
      // check user current region
      // history.push(`/${reg}/dashboard`);
      // history.push(`/${reg}/login`);
      // history.replace(`/${reg}/login`);
    }
  } catch (error) {
    this.authLoading = false;
    CommonStore.showErrorMessage(error.message || error);
   }
  };

  get isAuthenticated() {
    return (!!this.currentUser && !!this.currentToken); //&& !!this.currentToken;
  }

  signOut = async (next) => {
    try {
      let {data, error} = await logout();

      if (data) {
        clearLS();
        this.currentUser = null;
        this.currentToken = null;
        CommonStore.showSuccessMessage('Logout sucess!');

        if (typeof next === 'function') {
          next();
        }
        window.location.reload();
      } else {
        throw error
      }
    } catch (error) {
      CommonStore.showErrorMessage(error.message || error);
    }
  };
}

decorate(AuthStore, {
  currentUser: observable,
  currentToken: observable,
  authLoading: observable,
  isAuthenticated: computed,
  signInWithEmailAndPassword: action,
  getAdminPermission: action,
  signOut: action
});

export default new AuthStore();
