import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from "./serviceworker"
import moment from 'moment-timezone'
// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';
// var OneSignal = window.OneSignal || [];
// OneSignal.push(function () {
//   OneSignal.setDefaultNotificationUrl('http://localhost:3000/log-calls');
//   OneSignal.init({
//     appId: config.onesignal.appId,
//     autoResubscribe: true,
//     notifyButton: {
//       enable: true
//     }
//   });
//   OneSignal.showNativePrompt();
// });

moment.tz.setDefault("Asia/Hong_Kong");

ReactDOM.render(<App />, document.getElementById('root'));
// disable ServiceWorker
// registerServiceWorker();
serviceWorker.unregister();
