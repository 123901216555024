import axios from "../helper/Axios.js";
import { apiUrl, adminUrl } from "../config/config.js";

export async function getGoldUserApplicant() {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/users/applicant/gold-member`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data };
  }
}

export async function getGoldUserUploadDocument() {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/users/applicant/document-uploaded`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data };
  }
}

export async function getProvinces() {
  try {
    let response = await axios.Get({
      url: `${apiUrl}/provinces`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data };
  }
}

export async function getCities(provinceId) {
  try {
    let response = await axios.Get({
      url: `${apiUrl}/cities/jdparentid/${provinceId}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data };
  }
}

export async function getDistricts(cityId) {
  try {
    let response = await axios.Get({
      url: `${apiUrl}/districts/jdparentid/${cityId}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data };
  }
}

export async function getOrderInfo() {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/admins/order-info`
    })

    if (response.statusCode !== 200) {
      return { error: response.message };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data };
  }
}
