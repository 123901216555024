import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function loginWithEmailAndPassword({ url, username, password }) {
  try {
    let loginResponse = await axios.Post({
      url: `${url}/auth/login`,
      data: { username, password }
    });

    if (loginResponse.statusCode !== 200) {
      return { error: loginResponse.message };
    }

    return { data: loginResponse.data };
  } catch (e) {
    return { error: e.response.data };
  }
}

export async function logout() {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/auth/logout`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (e) {
    return { error: e.response.data };
  }
}

export async function getAdminPermission(url) {
  try {
    let response = await axios.Get({
      url: `${url}/admins/me`
    });

    if (response.statusCode !== 200) {
      return { error: response };
    }

    return { data: response.data };
  } catch (e) {
    return { error: e.response.data };
  }
}

// import { reactLocalStorage } from 'reactjs-localstorage';
//
// const begitu = {
//     setLocalStorage: ({ field, value }) => {
//         return reactLocalStorage.set(field, value);
//     },
//     getLocalStorage: (field) => {
//         return reactLocalStorage.get(field);
//     },
//     getCredentials: () => {
//         return reactLocalStorage.get('user');
//     }
// }
//
// export default begitu;
