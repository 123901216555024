import { action, decorate, observable, computed } from "mobx";
import {
  getGoldUserApplicant,
  getGoldUserUploadDocument,
  getProvinces,
  getCities,
  getDistricts,
  getOrderInfo
} from "../services/CommonService";

import * as FileSaver from 'file-saver';
import XLSX from 'xlsx';
class CommonStore {
  getOrderLoading = false;
  snackbarVariant = "success";
  snackbarMessage = "";
  snackbarShouldOpen = false;
  regionLoading = false;
  verificationDialogShouldOpen = false;
  goldUserApplicantModal = false;
  orderObject = {};
  goldUserApplicantArray = [];
  provincesArray = [];
  citiesArray = [];
  districtsArray = [];

  exportToExcel = async (filename, value = {}, isRaw = true) => {
    try {
      const fileName = `${filename}${Date()}`;
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      let data = {};
      if (isRaw) {
        const ws = XLSX.utils.json_to_sheet(value);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        data = new Blob([excelBuffer], { type: fileType });
      } else {
        data = new Blob([value], { type: fileType });
      }
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (error) {
      console.log(error);
    }
  }

  showAppSnackbar = ({ variant, message }) => {
    this.snackbarMessage = message;
    this.snackbarVariant = variant;
    this.snackbarShouldOpen = true;
  };

  closeAppSnackbar = () => {
    this.snackbarShouldOpen = false;
  };

  showSuccessMessage = message => {
    this.showAppSnackbar({
      variant: "success",
      message
    });
  };

  showSuccessMessageTimed = message => {
    this.showAppSnackbar({
      variant: "success",
      message
    });
  };

  showErrorMessageTimed = message => {
    this.showAppSnackbar({
      variant: "success",
      message
    });
  };

  showWarningMessage = message => {
    this.showAppSnackbar({
      variant: "warning",
      message
    });
  };

  showErrorMessage = message => {
    if (message && typeof message === "string") {
      this.showAppSnackbar({
        variant: "error",
        message
      });
    }
  };

  showVerificationDialog = () => {
    this.verificationDialogShouldOpen = true;
  };

  closeVerificationDialog = () => {
    this.verificationDialogShouldOpen = false;
  };

  getGoldUserApplicant = async () => {
    this.regionLoading = true;
    let { data, error } = await getGoldUserApplicant();
    this.regionLoading = false;

    if (error) {
      if (error.response) {
        this.showErrorMessage(error.response.data.message);
      } else {
        this.showErrorMessage(error);
      }
      // this.showErrorMessage(error);
      return;
    }

    if (data) {
      this.goldUserApplicantArray = data;
    }
  };

  getGoldUserUploadDocument = async () => {
    this.regionLoading = true;
    let { data, error } = await getGoldUserUploadDocument();
    this.regionLoading = false;

    if (error) {
      if (error.response) {
        this.showErrorMessage(error.response.data.message);
      } else {
        this.showErrorMessage(error);
      }
      // this.showErrorMessage(error);
      return;
    }

    if (data) {
      this.goldUserUploadedDocumentArray = data;
    }
  };

  getProvincesArrayFromServer = async () => {
    this.regionLoading = true;
    let { data, error } = await getProvinces();
    this.regionLoading = false;

    if (error) {
      this.showErrorMessage(error);
      return;
    }

    if (data) {
      this.provincesArray = data;
    }
  };

  getCitiesArrayFromServer = async provinceId => {
    this.regionLoading = true;
    let { data, error } = await getCities(provinceId);
    this.regionLoading = false;

    if (error) {
      this.showErrorMessage(error);
      return;
    }

    if (data) {
      this.citiesArray = data;
    }
  };

  getDistrictsArrayFromServer = async cityId => {
    this.regionLoading = true;
    let { data, error } = await getDistricts(cityId);
    this.regionLoading = false;

    if (error) {
      this.showErrorMessage(error);
      return;
    }

    if (data) {
      this.districtsArray = data;
    }
  };

  getOrderInfo = async () => {
    this.getOrderLoading = true;
    let { data, error } = await getOrderInfo();
    this.getOrderLoading = false;

    if (error) {
      this.showErrorMessage(error);
      return;
    }

    if (data) {
      this.orderObject = data;
    }
  };

  toggleGoldUserApplicantModal = () => {
    this.goldUserApplicantModal = !this.goldUserApplicantModal;
  };

  get provincesArrayForReactSelect() {
    if (this.provincesArray && this.provincesArray.length > 0) {
      return this.provincesArray.map(provinceObject => {
        return {
          value: provinceObject._id,
          label: provinceObject.name
        };
      });
    }
    return [];
  }

  get provinceDictionary() {
    const dictionary = {};
    if (this.provincesArray && this.provincesArray.length > 0) {
      this.provincesArray.forEach(provinceObject => {
        dictionary[provinceObject._id] = provinceObject;
      });
    }
    return dictionary;
  }

  get citiesArrayForReactSelect() {
    if (this.citiesArray && this.citiesArray.length > 0) {
      return this.citiesArray.map(cityObject => {
        return {
          value: cityObject._id,
          label: cityObject.name
        };
      });
    }
    return [];
  }

  get cityDictionary() {
    const dictionary = {};
    if (this.citiesArray && this.citiesArray.length > 0) {
      this.citiesArray.forEach(cityObject => {
        dictionary[cityObject._id] = cityObject;
      });
    }
    return dictionary;
  }

  get districtsArrayForReactSelect() {
    if (this.districtsArray && this.districtsArray.length > 0) {
      return this.districtsArray.map(districtObject => {
        return {
          value: districtObject._id,
          label: districtObject.name
        };
      });
    }
    return [];
  }

  get districtDictionary() {
    const dictionary = {};
    if (this.districtsArray && this.districtsArray.length > 0) {
      this.districtsArray.forEach(districtObject => {
        dictionary[districtObject._id] = districtObject;
      });
    }
    return dictionary;
  }
}

decorate(CommonStore, {
  goldUserApplicantArray: observable,
  goldUserUploadedDocumentArray: observable,
  provincesArray: observable,
  citiesArray: observable,
  districtsArray: observable,
  regionLoading: observable,
  verificationDialogShouldOpen: observable,
  goldUserApplicantModal: observable,
  snackbarVariant: observable,
  snackbarMessage: observable,
  snackbarShouldOpen: observable,
  orderObject: observable,
  getOrderLoading: observable,

  showSuccessMessage: action,
  showErrorMessage: action,
  showAppSnackbar: action,
  closeAppSnackbar: action,
  showVerificationDialog: action,
  closeVerificationDialog: action,
  getProvinceArrayFromServer: action,
  getCitiesArrayFromServer: action,
  getDistrictsArrayFromServer: action,
  getOrderInfo: action,

  provincesArrayForReactSelect: computed,
  citiesArrayForReactSelect: computed,
  districtsArrayForReactSelect: computed,
  provinceDictionary: computed,
  cityDictionary: computed,
  districtDictionary: computed
});

export default new CommonStore();
